/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations";

/*
* LAYOUT
*/

//@import "components/blender";

body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	background-color: $color-secondary;
	background-image: linear-gradient(
			to top right,
			rgba(#867268, 0.7),
			rgba(#5f423e, 0.7)
		),
		url(/assets/images/overlay@2x.webp), url(/assets/images/bg_homepage.webp);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	min-height: 50vh;
	flex-grow: 1;

	&::before {
		content: "";
		@include fill;
	}

	.no-webp & {
		flex-grow: 1;
		background-image: linear-gradient(
				to top right,
				rgba(#867268, 0.7),
				rgba(#5f423e, 0.7)
			),
			url(/assets/images/overlay@2x.png), url(/assets/images/bg_homepage.jpg);
	}

	&.jidelni-listek {
		background-image: none;
		background-color: #bd5e42;
		display: flex;
		align-items: center;
		padding: 3em;

		&::before {
			content: normal;
		}

		.menu__content {
			color: white;
			flex-basis: 66%;
			font-size: rem(26px);
		}

		h1 {
			font-family: $font-headings;
			text-align: left;
			color: white;
			font-size: rem(60px);
			margin-bottom: 0.5em;
		}

		h2 {
			font-family: $font-headings;
			font-size: rem(40px);
			text-transform: none;
			text-align: left;
			margin: 0 0 0.5em;
		}

		p {
			margin: 0.3em 0;
		}

		.menu__gallery {
			margin-left: 4em;
			flex-basis: calc(40% - 4em);
			flex-grow: 0;
		}
	}
}

.gallery-1 {
	margin: 0 0 2em;
}

.carousel-gallery {
	display: flex;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style-type: none;
	overflow-x: auto;
	position: relative;

	&__item {
		width: 100%;
		flex: 1 0 100%;
		position: relative;
		overflow: hidden;
		left: -100%;

		&:only-child {
			left: 0;
		}

		a {
			display: block;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}

.section {
}

.intro {
	color: white;
	text-align: center;
	margin: 5.5% 0;

	&__content {
		max-width: 800px;
	}

	&__text {
		font-size: rem(18px);
	}
}

.big {
	font-size: rem(40px);
	font-family: $font-headings;
	margin: 0 0 0.15em;
}

.smaller {
	font-size: rem(24px);
	text-transform: uppercase;
	margin-top: 0.33em;
}

.food-table {
	margin: 0 0 1.5em 30px;
	width: calc(100% - 30px);

	tr,
	td {
		border: 0 !important;
		background-color: transparent !important;
	}

	td {
		vertical-align: middle;
		padding: 5px 0 !important;

		p {
			margin: 0;
		}
	}

	td:last-child {
		width: 3em;
		text-align: right;
	}

	.food_allergens {
		font-size: rem(20px);
	}
}
