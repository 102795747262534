/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
/*
* LAYOUT
*/
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background-color: #6b4f43;
  background-image: linear-gradient(to top right, rgba(134, 114, 104, 0.7), rgba(95, 66, 62, 0.7)), url(/assets/images/overlay@2x.webp), url(/assets/images/bg_homepage.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  min-height: 50vh;
  flex-grow: 1;
}

main::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.no-webp main {
  flex-grow: 1;
  background-image: linear-gradient(to top right, rgba(134, 114, 104, 0.7), rgba(95, 66, 62, 0.7)), url(/assets/images/overlay@2x.png), url(/assets/images/bg_homepage.jpg);
}

main.jidelni-listek {
  background-image: none;
  background-color: #bd5e42;
  display: flex;
  align-items: center;
  padding: 3em;
}

main.jidelni-listek::before {
  content: normal;
}

main.jidelni-listek .menu__content {
  color: white;
  flex-basis: 66%;
  font-size: 1.625rem;
}

main.jidelni-listek h1 {
  font-family: Pacifico, cursive;
  text-align: left;
  color: white;
  font-size: 3.75rem;
  margin-bottom: 0.5em;
}

main.jidelni-listek h2 {
  font-family: Pacifico, cursive;
  font-size: 2.5rem;
  text-transform: none;
  text-align: left;
  margin: 0 0 0.5em;
}

main.jidelni-listek p {
  margin: 0.3em 0;
}

main.jidelni-listek .menu__gallery {
  margin-left: 4em;
  flex-basis: calc(40% - 4em);
  flex-grow: 0;
}

.gallery-1 {
  margin: 0 0 2em;
}

.carousel-gallery {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-x: auto;
  position: relative;
}

.carousel-gallery__item {
  width: 100%;
  flex: 1 0 100%;
  position: relative;
  overflow: hidden;
  left: -100%;
}

.carousel-gallery__item:only-child {
  left: 0;
}

.carousel-gallery__item a {
  display: block;
}

.carousel-gallery__item img {
  width: 100%;
  height: auto;
}

.intro {
  color: white;
  text-align: center;
  margin: 5.5% 0;
}

.intro__content {
  max-width: 800px;
}

.intro__text {
  font-size: 1.125rem;
}

.big {
  font-size: 2.5rem;
  font-family: Pacifico, cursive;
  margin: 0 0 0.15em;
}

.smaller {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 0.33em;
}

.food-table {
  margin: 0 0 1.5em 30px;
  width: calc(100% - 30px);
}

.food-table tr,
.food-table td {
  border: 0 !important;
  background-color: transparent !important;
}

.food-table td {
  vertical-align: middle;
  padding: 5px 0 !important;
}

.food-table td p {
  margin: 0;
}

.food-table td:last-child {
  width: 3em;
  text-align: right;
}

.food-table .food_allergens {
  font-size: 1.25rem;
}
